/**
 *
 * IndexPage
 *
 *
 */

import React, { memo, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';

import PropTypes from 'prop-types';

import Home from 'containers/Home';
import SEO from 'components/seo';
import theme from 'utils/theme';

const IndexPage = ({ user }) => {
  return (
    <Container>
      <SEO title="A place to celebrate a wonderful life." />
      <Home />
    </Container>
  );
};

IndexPage.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = ({ user }) => ({ user });
const withConnect = connect(mapStateToProps);
export default compose(memo, withConnect)(IndexPage);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Comingsoon = styled.main``;
